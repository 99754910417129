import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {Link} from "gatsby";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature, {MiniQuote} from "../../components/bricks/ThemedSingleFeature";
import {Box, XCol} from "../../components/xui/Box";

const VsHacknplan = ({location}) => (
  <DefaultLayout
    title="Codecks vs Hacknplan"
    description="We love to see more tools specifically made for game developers! We might be biased here, but we think Codecks is the better Hacknplan alternative:"
    location={location}
  >
    <Hero
      size="sm"
      title="Codecks vs Hacknplan"
      subline="We love to see more tools specifically made for game developers! Hacknplan is a good tool, but (we might be biased here) we think Codecks is the better Hacknplan alternative. Here's why:"
    />
    <ThemeBox theme={themeBright} withBorder title="The interface">
      <ThemedSingleFeature
        childAsDescription
        img="gametitles.jpg"
        title="Made for game developers, by game developers"
      >
        The team creating Codecks has many years of development experience having worked on big AAA
        games as well as tiny indie games and we think it shows. In Codecks you will find that we
        poured all our learnings into the best tool for game developers, made by game developers.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="trello-3.png"
        imgRight
        title="Fantastic UX is not optional"
      >
        We go to great lengths to deliver a playful and joyful experience. In Codecks everything is
        rich with visual information - including your beautiful deck and card images. This is the
        tool that not only your producer will love, but everybody on your team: from artist to
        programmer, from designer to writer.
      </ThemedSingleFeature>
      <ThemedSingleFeature childAsDescription img="milestones-1.png" title="We're improving fast">
        Like Hacknplan, Codecks launched in 2015. When you scan our{" "}
        <Link to="/changelog/">changelog</Link>, you'll notice that we're still constantly working
        on fresh and unique ideas. Barely a week goes by without substantial improvements to the
        tool and we're going faster as we learn more from you.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Managing projects">
      <ThemedSingleFeature childAsDescription img="trello-5.png" title="Workflow as part of a card">
        Hacknplan uses standard Kanban-style lanes for indicating a card's progress, Codecks uses an
        innovative design language incoroporating this information within each card. You'll quickly
        learn that a gray or brown card hasn't been started yet, while blue means "in progress" and
        green cards are "done". There are also distinct colors for blocked cards and cards in
        review.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="decks-3.png"
        imgRight
        title="Space for all your ideas"
      >
        Codecks uses decks as container for cards. This allows you to create a deck for each feature
        type to slice your backlog into manageable chunks. Hundreds of cards can be effectively
        managed this way and if you feel you need an even higher level of abstraction, Codecks
        offers a great multi-project support allowing you to manage several projects in parallel or
        separately.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeBright} withBorder title="Let's have a conversation about conversations">
      <ThemedSingleFeature childAsDescription img="conversations-notifications-1.png">
        Having productive conversations is a corner-piece of any successful project managed by more
        than one person. This is why we focused a lot of our attention to nail this part of the
        process. We have come up with a unique conversation system that is aimed at preventing loose
        ends.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-1.png"
        imgRight
        title="Each idea deserves its own thread"
      >
        All too often it happens that people get notified of a comment but are not in a position to
        reply right away. Hardly any tool approaches this common problem effectively and Hacknplan
        suffers from the same issues. Codecks employs a few ideas to ensure that the no comment gets
        lost. First off, every conversation happens inside a thread. This not only allows to have
        separate discussions on a single cards, but also allows you to define who is part of a
        conversation and whether this conversation can be closed.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-4.png"
        title="Who takes part and did we come to a conclusion?"
      >
        When creating a conversation, you may select the participants either from a list of common
        conversation partners or by simply mentioning them in your message. Every participant will
        get notified of this conversation and Codecks ensures that the conversation comes to a
        conclusion by regularly reminding all participants of any open conversation. If people feel
        like they can't contribute any more, they can always opt out.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        img="conversations-2.png"
        imgRight
        title="Highlight important conversations"
      >
        Even more interestingly, two workflow states are directly bound to conversations. Both,
        blocking a card and reviews, are initiated by starting a conversation. The card will remain
        in that highly visible state and send regular reminders to all participants until the
        conversation has been marked as resolved.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="Build your Community">
      <ThemedSingleFeature childAsDescription title="Open Development" img="cd-collab.png">
        Community building is an integral part of games marketing now and Codecks has unseen support
        for transparent development built-in right to its core. Unlike in Hacknplan you can share as
        much or little as you want with your fans.
      </ThemedSingleFeature>
      <ThemedSingleFeature
        childAsDescription
        title="Discord Community"
        imgRight
        img="discord-2.png"
      >
        Our Discord bot <Link to="/blog/discord-for-game-developers/">Decky</Link> goes beyond
        simple notifications. It will listen for feedback and bug reports from your players. Create
        cards inside Codecks automatically for feedback that passes certain upvoting thresholds,
        reply to users right from inside of Codecks and keep your community engaged and growing with
        automatic notifications and leaderboards.
      </ThemedSingleFeature>
      <ThemedSingleFeature childAsDescription title="Public Decks" img="open-decks-1.png">
        Public decks allows you to share parts of your projects on the web. Use it to share your
        roadmap or get your community excited by sharing tasks that you're currently working on.
        Check the amazing projects that are already sharing their projects{" "}
        <Box
          as="a"
          color="buttonSecondary"
          bold
          hover_color="purple300"
          href="https://open.codecks.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Box>
        .
      </ThemedSingleFeature>

      <XCol maxWidth="md" ml="auto" mr="auto">
        <MiniQuote
          authorName="Felipe Falanghe"
          authorInfo="Kerbal Space Program"
          companyName="Floating Origin Interactive"
          img="felipe-falanghe"
        >
          Codecks is an order of magnitude above all project management solutions we've used before.
          I've used other integrated tools before, but this takes it to a whole new level.
        </MiniQuote>
      </XCol>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default VsHacknplan;
